import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as icons from './icons'
import { EmailPattern } from './constants'
import { InputField } from './input-field'
import { QueryDrawer } from './query-drawer'
import { SubmitButton } from './submit-button'
import { useMenuQueries } from './use-menu-queries'
import { useSafeUpdateQuery } from './use-safe-update-query'

const StyledHeading = styled.h3`
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  background: linear-gradient(180deg, #fff 0%, #9090ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${css({
    fontSize: 6,
    pb: 0,
  })};
`

const dataSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      pattern: EmailPattern,
    },
  },
  required: ['email'],
}

const formName = 'password-recovery'
const preloadSchemaKeys = Forms.findTranslationKeys(formName, dataSchema)

export function PasswordRecovery() {
  const dispatch = ReactRedux.useDispatch()
  const history = ReactRouter.useHistory()
  const translate = Herz.I18n.useTranslate()
  const { registerQuery } = useMenuQueries()
  const closeQuery = useSafeUpdateQuery({ 'reset-password': null })

  function handleSubmit(errors, data) {
    if (Object.keys(errors).length === 0) {
      dispatch(
        Api.resetPassword(data.email, {
          success: () => history.push({ search: closeQuery }),
          version: 1,
        })
      )
    }
  }

  return (
    <Common.Box
      flexBasis="0"
      flexGrow="1"
      flexShrink="1"
      flexDirection="column"
      display="flex"
      pt={3}
    >
      <Common.Box
        display="flex"
        backgroundColor="g-bg"
        flexGrow="1"
        flexShrink="1"
        flexBasis="0"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Common.Box mx="auto" maxWidth="350px">
          <React.Fragment>
            <Common.Space pb={2}>
              <Common.Box
                width="100%"
                height="120px"
                backgroundImage={`url(${translate(
                  'password-recovery.heading.image'
                )})`}
                backgroundSize="cover"
                borderRadius="12px"
                px={1}
                mb={1}
              >
                <StyledHeading>
                  {translate('password-recovery.banner-text')}
                </StyledHeading>
              </Common.Box>
              <Common.Text
                as="p"
                color="static-white"
                fontSize={1}
                opacity="0.8"
              >
                {translate('password-recovery.input-page.content')}
              </Common.Text>
            </Common.Space>
            <Forms.Provider
              schema={dataSchema}
              name={formName}
              onSubmit={handleSubmit}
            >
              <Common.Flex flexDirection="column">
                <InputField
                  autoComplete="email"
                  inputMode="email"
                  prependIcon={<icons.Email />}
                  scope="#/properties/email"
                />
                <Common.Box pt={4} display="flex" justifyContent="center">
                  <SubmitButton
                    data-testid="password-recovery-submit"
                    stretch
                  />
                </Common.Box>
              </Common.Flex>
            </Forms.Provider>
          </React.Fragment>

          <Common.Box
            color="static-white"
            pt={2}
            textAlign="center"
            fontSize={2}
            opacity="0.8"
          >
            {translate('login-page.create-account.content')}
            <Common.Space pt={0}>
              <ReactRouter.Link
                color="static-white"
                to={`?${registerQuery}`}
                data-testid="login-page-register"
              >
                <Common.Text textDecoration="underline" fontWeight="800">
                  {translate('login-page.create-account.cta')}
                </Common.Text>
              </ReactRouter.Link>
            </Common.Space>
          </Common.Box>
        </Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'password-recovery.title',
    'password-recovery.success-page.title',
    'password-recovery.success-page.content',
    'password-recovery.input-page.title',
    'password-recovery.heading.image',
    'password-recovery.input-page.content',
    'password-recovery.banner-text',
    'password-recovery.register',
    'login-page.create-account.cta',
    'login-page.create-account.content',
    ...preloadSchemaKeys,
  ],
  PasswordRecovery
)

export function ResetPasswordDrawer() {
  const history = ReactRouter.useHistory()
  const translate = Herz.I18n.useTranslate()
  const { loginQuery } = useMenuQueries()

  function handleBack() {
    history.push(`?${loginQuery}`)
  }

  return (
    <QueryDrawer
      activeQueryName="reset-password"
      title={translate('password-recovery.title')}
      onSecondaryAction={handleBack}
    >
      <PasswordRecovery />
    </QueryDrawer>
  )
}

Herz.I18n.Loader.preload(['password-recovery.title'], ResetPasswordDrawer)

export default ResetPasswordDrawer
